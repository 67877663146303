'use client';

import clsx from 'clsx';
import Link from 'next/link';
import type { FC } from 'react';
import { useState } from 'react';
import { isSvg } from '@/utils/image-utils';
import { Menu } from '@/components/raven/Menu';
import type { WPImage } from '@/types/entities';
import { Image } from '@/components/raven/Image';
import { useEventsByType } from '@/utils/hooks/use-events';
import { PrivacySettings } from '@/components/PrivacySettings';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import ArenaLogo from '@/assets/raven/images/logo-arena-group-v2.svg';
import { ARTICLE_FETCH, ARTICLE_FETCH_COMPLETE } from '@/utils/events';
import { SocialNetworks } from '@/components/raven/global/SocialNetworks';

import styles from './styles.module.css';

export interface FooterProps {
	copyright: string;
	logo?: WPImage;
	menus: AppSettings['menus'];
}

export const Footer: FC<FooterProps> = ({ copyright, logo, menus }) => {
	const copyrightParsed = copyright
		? copyright.replace(/\[year\]/g, new Date().getFullYear().toString())
		: '';
	const [fetching, setFetching] = useState(false);
	useEventsByType(ARTICLE_FETCH, () => setFetching(true));
	useEventsByType(ARTICLE_FETCH_COMPLETE, () => setFetching(false));
	return (
		!fetching && (
			<footer className={clsx(styles.siteFooter, 'is-dark')} id="main-footer">
				<div className={styles.siteFooterContainer}>
					<div className={styles.siteFooterTop}>
						{logo && (
							<Link
								aria-label="Go to Homepage"
								className={styles.siteFooterLogo}
								href="/"
							>
								<Image
									alt={logo.alt_text}
									height={logo.media_details.height}
									src={logo.source_url}
									unoptimized={isSvg(logo.source_url)}
									width={logo.media_details.width}
								/>
							</Link>
						)}

						<SocialNetworks
							ariaLabel="Open %s social network in a new tab"
							networks={menus['social-links']}
						/>
					</div>

					{menus.topics && menus.topics.length > 0 && (
						<>
							<hr className={styles.siteFooterSeparator} />
							<Menu
								ariaLabel="Categories Menu"
								className={styles.siteFooterCategories}
								linkClassName={styles.siteFooterCategoriesLink}
								links={menus.topics}
								listItemClassName={styles.siteFooterCategoriesItem}
								menuAccordion
							/>
						</>
					)}

					<hr className={styles.siteFooterSeparator} />

					<div className={styles.siteFooterLower}>
						<Menu
							ariaLabel="Footer Menu"
							className={styles.siteFooterList}
							linkClassName={styles.siteFooterListItemLink}
							links={menus.legal}
							listItemClassName={styles.siteFooterListItem}
							privacySettings={PrivacySettings}
						/>

						{copyright && (
							<p className={styles.siteFooterCopyright}>
								&copy; {copyrightParsed}
							</p>
						)}

						<div className={styles.siteFooterGroupLogo}>
							<Link
								aria-label="Go to The Arena Group Homepage"
								className={styles.siteFooterArenaLink}
								href="https://thearenagroup.net/"
								target="_blank"
							>
								<Image
									alt="The Arena Group Logo"
									height={39}
									src={ArenaLogo.src}
									unoptimized
									width={75}
								/>
							</Link>
						</div>
					</div>
				</div>
			</footer>
		)
	);
};
