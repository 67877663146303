import type { FC } from 'react';
import type { IconProps } from '@/types/icons';

const FILL_COLORS = {
	CHECK: '#3068F7',
	CHECK_BG: '#FEFEFF',
	MAIN: '#2D65F6',
	X: '#FAFBFF',
};

export const PrivacySettingsIcon: FC<IconProps> = ({
	height = 32,
	width = 32,
	...rest
}) => (
	<svg
		aria-hidden
		fill="none"
		focusable={false}
		height={height}
		viewBox="0 0 550 445"
		width={width}
		xmlns="http://www.w3.org/2000/svg"
		{...rest}
	>
		<path
			d="
M248.999268,335.120667 
	C217.836563,335.082489 187.166046,335.485474 156.512558,334.936707 
	C115.664734,334.205383 83.089615,317.297760 60.858952,282.587585 
	C18.058371,215.760254 59.687679,125.366402 138.278290,114.038223 
	C143.208099,113.327644 148.224426,112.884819 153.200989,112.880592 
	C235.025055,112.811081 316.850098,112.597717 398.673004,112.913887 
	C454.795715,113.130745 502.361969,157.086380 507.781647,212.872360 
	C513.341492,270.100525 473.498962,322.783936 416.818268,332.874481 
	C409.638428,334.152710 402.267975,334.918091 394.981628,334.949707 
	C346.488068,335.160126 297.993378,335.106140 248.999268,335.120667 
M153.722641,315.791595 
	C185.368896,315.802765 217.015518,315.747131 248.660950,315.914246 
	C252.391174,315.933960 253.840073,314.705322 254.779495,311.194885 
	C268.079376,261.495514 281.522614,211.834457 294.916412,162.160187 
	C297.585602,152.260773 300.193146,142.344742 302.881348,132.240005 
	C301.669769,132.046265 301.027252,131.853378 300.384766,131.853638 
	C250.250809,131.873260 200.094955,130.991364 149.988785,132.188034 
	C95.021484,133.500839 53.723759,187.146896 64.645767,241.015823 
	C73.349785,283.945282 107.600235,312.995026 153.722641,315.791595 
M415.398590,244.101456 
	C408.687378,237.467148 401.976166,230.832855 394.064667,223.012009 
	C405.306152,212.308548 415.897766,202.200653 426.516998,192.121826 
	C432.662537,186.289017 433.704773,180.705597 429.421844,176.167130 
	C425.128296,171.617355 419.278503,172.394394 413.409943,178.292587 
	C404.131714,187.617752 394.859009,196.948486 385.566650,206.259628 
	C384.329651,207.499146 382.991669,208.637833 380.980896,210.483810 
	C369.789368,199.041519 359.024292,187.925079 348.124481,176.942383 
	C343.411407,172.193466 337.529022,172.009857 333.532562,176.132355 
	C329.566040,180.223953 330.080688,186.063843 334.788300,190.716019 
	C344.617523,200.429520 354.442780,210.147079 364.247833,219.885010 
	C365.492126,221.120789 366.605072,222.488815 368.842316,224.979523 
	C364.485687,228.799103 360.329285,232.144623 356.519165,235.846527 
	C348.876617,243.272003 341.347870,250.818954 333.921906,258.461304 
	C329.904785,262.595490 329.862640,268.163727 333.441589,271.910492 
	C337.147369,275.790070 342.618103,276.028564 346.907227,272.351074 
	C348.798157,270.729767 350.466187,268.848785 352.237854,267.088135 
	C362.014832,257.371796 371.792603,247.656250 381.791870,237.720032 
	C383.176941,239.002899 384.409119,240.075653 385.564789,241.225311 
	C395.480896,251.089813 405.346313,261.005676 415.306061,270.825836 
	C419.899353,275.354767 425.564728,275.657013 429.562042,271.822327 
	C433.673492,267.878143 433.381195,261.953339 428.708191,257.219513 
	C424.498016,252.954575 420.172821,248.803192 415.398590,244.101456 
z"
			fill={FILL_COLORS.MAIN}
		/>
		<path
			d="
M153.250488,315.778839 
	C107.600235,312.995026 73.349785,283.945282 64.645767,241.015823 
	C53.723759,187.146896 95.021484,133.500839 149.988785,132.188034 
	C200.094955,130.991364 250.250809,131.873260 300.384766,131.853638 
	C301.027252,131.853378 301.669769,132.046265 302.881348,132.240005 
	C300.193146,142.344742 297.585602,152.260773 294.916412,162.160187 
	C281.522614,211.834457 268.079376,261.495514 254.779495,311.194885 
	C253.840073,314.705322 252.391174,315.933960 248.660950,315.914246 
	C217.015518,315.747131 185.368896,315.802765 153.250488,315.778839 
M156.694916,268.878235 
	C163.232330,275.014984 168.927567,274.299652 175.640457,266.418884 
	C196.687744,241.709824 217.718246,216.986465 238.749710,192.263947 
	C243.310196,186.903091 243.277939,181.175674 238.695114,177.328156 
	C234.248672,173.595123 229.100067,174.495682 224.421478,179.947495 
	C210.328049,196.370163 196.269440,212.822708 182.195358,229.261978 
	C176.398041,236.033539 170.595901,242.800964 163.813889,250.716492 
	C161.823441,248.125961 160.483322,245.970154 158.750671,244.198196 
	C151.300827,236.579453 143.752914,229.056152 136.203857,221.535080 
	C133.260406,218.602524 129.455521,216.848953 125.840164,219.102264 
	C123.186623,220.756134 120.276215,223.981674 119.932541,226.805252 
	C119.582184,229.683731 121.506119,233.621185 123.686646,235.892944 
	C134.289810,246.939819 145.313004,257.583496 156.694916,268.878235 
z"
			fill={FILL_COLORS.CHECK_BG}
		/>
		<path
			d="
M415.648438,244.351593 
	C420.172821,248.803192 424.498016,252.954575 428.708191,257.219513 
	C433.381195,261.953339 433.673492,267.878143 429.562042,271.822327 
	C425.564728,275.657013 419.899353,275.354767 415.306061,270.825836 
	C405.346313,261.005676 395.480896,251.089813 385.564789,241.225311 
	C384.409119,240.075653 383.176941,239.002899 381.791870,237.720032 
	C371.792603,247.656250 362.014832,257.371796 352.237854,267.088135 
	C350.466187,268.848785 348.798157,270.729767 346.907227,272.351074 
	C342.618103,276.028564 337.147369,275.790070 333.441589,271.910492 
	C329.862640,268.163727 329.904785,262.595490 333.921906,258.461304 
	C341.347870,250.818954 348.876617,243.272003 356.519165,235.846527 
	C360.329285,232.144623 364.485687,228.799103 368.842316,224.979523 
	C366.605072,222.488815 365.492126,221.120789 364.247833,219.885010 
	C354.442780,210.147079 344.617523,200.429520 334.788300,190.716019 
	C330.080688,186.063843 329.566040,180.223953 333.532562,176.132355 
	C337.529022,172.009857 343.411407,172.193466 348.124481,176.942383 
	C359.024292,187.925079 369.789368,199.041519 380.980896,210.483810 
	C382.991669,208.637833 384.329651,207.499146 385.566650,206.259628 
	C394.859009,196.948486 404.131714,187.617752 413.409943,178.292587 
	C419.278503,172.394394 425.128296,171.617355 429.421844,176.167130 
	C433.704773,180.705597 432.662537,186.289017 426.516998,192.121826 
	C415.897766,202.200653 405.306152,212.308548 394.064667,223.012009 
	C401.976166,230.832855 408.687378,237.467148 415.648438,244.351593 
z"
			fill={FILL_COLORS.X}
		/>
		<path
			d="
M156.441193,268.624084 
	C145.313004,257.583496 134.289810,246.939819 123.686646,235.892944 
	C121.506119,233.621185 119.582184,229.683731 119.932541,226.805252 
	C120.276215,223.981674 123.186623,220.756134 125.840164,219.102264 
	C129.455521,216.848953 133.260406,218.602524 136.203857,221.535080 
	C143.752914,229.056152 151.300827,236.579453 158.750671,244.198196 
	C160.483322,245.970154 161.823441,248.125961 163.813889,250.716492 
	C170.595901,242.800964 176.398041,236.033539 182.195358,229.261978 
	C196.269440,212.822708 210.328049,196.370163 224.421478,179.947495 
	C229.100067,174.495682 234.248672,173.595123 238.695114,177.328156 
	C243.277939,181.175674 243.310196,186.903091 238.749710,192.263947 
	C217.718246,216.986465 196.687744,241.709824 175.640457,266.418884 
	C168.927567,274.299652 163.232330,275.014984 156.441193,268.624084 
z"
			fill={FILL_COLORS.CHECK}
		/>
	</svg>
);
