import clsx from 'clsx';
import { isSvg } from '@/utils/image-utils';
import { usePathname } from 'next/navigation';
import { Image } from '@/components/raven/Image';
import { LinkWrapper } from '@/components/LinkWrapper';
import { isOrEndsWith } from '@/utils/generate-submenu';
import { useRef, type FC, useState, useEffect } from 'react';
import { useNavigationContext } from '@/context/NavigationContext';

import styles from './styles.module.css';

export interface SmallMenuItem {
	id: number;
	text: string;
	url: string;
}
export interface MenuAttributes {
	icon?: string;
	menuItems: SmallMenuItem[];
	title: string;
	url: string;
}

interface SubNavigationProps {
	className?: string;
	isSubNavigationOpen: boolean;
	menu: MenuAttributes;
}

export const SubNavigation: FC<SubNavigationProps> = ({
	className,
	isSubNavigationOpen,
	menu,
}) => {
	const {
		handleSetIsSubNavRender,
		handleSetSubNavigationOpen,
		isFirstSubNavRender,
	} = useNavigationContext();
	const pathname = usePathname();
	const subNavigationRef = useRef<HTMLDivElement>(null);
	const lastPathSegment = useRef<string>('');
	const subNavigationScrollWrapperRef = useRef<HTMLDivElement>(null);
	const [isScrollable, setIsScrollable] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);
	const [isScrolled, setIsScrolled] = useState(false);

	const segments = pathname.split('/').filter((segment) => segment);
	lastPathSegment.current = segments[segments.length - 1];

	// Set sub navigation open if the first path segment matches the menu url
	useEffect(() => {
		const shouldOpenSubNavigation =
			isOrEndsWith(menu.url, pathname) ||
			menu.menuItems.some((item) => isOrEndsWith(item.url, pathname));

		handleSetSubNavigationOpen(shouldOpenSubNavigation);
		handleSetIsSubNavRender(false);
	}, [pathname, handleSetSubNavigationOpen, menu, handleSetIsSubNavRender]);

	useEffect(() => {
		const wrapper = subNavigationScrollWrapperRef.current;

		const handleResize = () => {
			if (wrapper && wrapper.scrollWidth) {
				if (wrapper.scrollWidth > wrapper.clientWidth) {
					setIsScrollable(true);
				} else {
					setIsScrollable(false);
				}
			}
		};

		const handleScroll = () => {
			if (wrapper) {
				if (
					wrapper?.scrollWidth === wrapper?.clientWidth ||
					wrapper.scrollLeft >= wrapper.scrollWidth - wrapper.clientWidth
				) {
					setIsScrolling(false);
					setIsScrolled(true);
				} else if (wrapper.scrollLeft > 0) {
					setIsScrolling(true);
					setIsScrolled(false);
				} else {
					setIsScrolling(false);
					setIsScrolled(false);
				}
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);
		if (wrapper) {
			wrapper.addEventListener('scroll', handleScroll);
		}

		return () => {
			window.removeEventListener('resize', handleResize);

			if (wrapper) {
				wrapper.removeEventListener('scroll', handleScroll);
			}
		};
	});

	const hasMenuItems = menu.menuItems.length > 0;
	const shouldFirstRender = hasMenuItems && isFirstSubNavRender;
	const isHidden = !isSubNavigationOpen && !shouldFirstRender;

	return (
		<nav
			aria-hidden={isHidden}
			aria-label={`Sub Navigation: ${menu.title}`}
			className={clsx(styles.subNavigation, className, {
				[styles.subNavigationScrollWrapperScrollable]: isScrollable,
				[styles.subNavigationScrollWrapperScrolled]: isScrolled,
				[styles.subNavigationScrollWrapperScrolling]: isScrolling,
			})}
			ref={subNavigationRef}
		>
			<div
				className={styles.subNavigationScrollWrapper}
				ref={subNavigationScrollWrapperRef}
			>
				{(menu.icon || menu.title) && (
					<div className={styles.subNavigationTitleWrapper}>
						{menu.icon && (
							<Image
								alt=""
								className={styles.subNavigationIcon}
								height={20}
								priority
								sizes="20px"
								src={`${menu.icon && menu.icon ? menu.icon : ''}`}
								unoptimized={isSvg(menu.icon)}
								width={20}
							/>
						)}
						{menu.title && (
							<p className={styles.subNavigationTitle} id="sub-navigation">
								{menu.title}
							</p>
						)}
					</div>
				)}
				{menu.menuItems.length > 0 && (
					<ul className={styles.subNavigationList}>
						{menu.menuItems.map((link) => {
							const lastLink =
								link.url.substring(link.url.lastIndexOf('/') + 1) ===
								lastPathSegment.current;

							return (
								<li className={styles.subNavigationItem} key={link.id}>
									<LinkWrapper
										className={clsx(
											styles.subNavigationLink,
											lastLink ? styles.subNavigationLinkActive : undefined,
										)}
										href={link.url}
									>
										{link.text}
									</LinkWrapper>
								</li>
							);
						})}
					</ul>
				)}
			</div>
		</nav>
	);
};
