import clsx from 'clsx';
import { type FC, useMemo } from 'react';
import { Image } from '@/components/raven/Image';
import { isValidUrl } from '@/utils/is-valid-url';
import More from '@themaven-net/raven-assets/More';
import Caret from '@themaven-net/raven-assets/Caret';
import type { MenuItemEntity } from '@/types/entities';
import { LinkWrapper } from '@/components/LinkWrapper';
import CaretOpen from '@themaven-net/raven-assets/CaretOpen';
import { MegaMenuPostsList } from '@/components/raven/MegaMenu/MegaMenuPostsList';

import styles from './styles.module.css';

interface MegaMenuItemProps {
	activeMenuItem: number;
	isSmallMenu: boolean;
	link: MenuItemEntity;
	toggleActiveMenu: (index: number, isSubNav: boolean) => void;
}

interface SmallMenuItem {
	id: number;
	text: string;
	url: string;
}

const MENU_LIMIT = 7;

export const MegaMenuItem: FC<MegaMenuItemProps> = ({
	activeMenuItem,
	isSmallMenu,
	link,
	toggleActiveMenu,
}) => {
	const linkChildren = link.children || [];
	const hasChildren: boolean = Boolean(link.children?.length);
	let postsLists = linkChildren.filter(
		(child) =>
			child.menu_item_type === 'group' &&
			Array.isArray(child.children) &&
			child.children.length > 0 &&
			child.children[0]?.menu_item_type === 'postslist',
	);

	const isMoreButton = link.classes?.includes('more-button');

	const menuItems = useMemo<SmallMenuItem[]>(() => {
		const children = link.children || [];
		const items: SmallMenuItem[] = [];

		if (!isMoreButton) {
			items.push({
				id: link.ID,
				text: `All ${link.title}`,
				url: link.url,
			});
		}

		children.forEach((child) => {
			if (child.menu_item_type === 'postslist') {
				return;
			}

			if (child.menu_item_type === 'group') {
				child.children.forEach((groupChild) => {
					if (groupChild.menu_item_type === 'postslist') {
						return;
					}

					items.push({
						id: groupChild.ID,
						text: groupChild.title,
						url: groupChild.url,
					});
				});
			} else {
				items.push({
					id: child.ID,
					text: child.title,
					url: child.url,
				});
			}
		});

		return items;
	}, [link, isMoreButton]);

	// Only consider first 14 items (2 chunks max)
	const totalItems = Math.min(menuItems.length, MENU_LIMIT * 2);
	const needsTwoChunks = totalItems > MENU_LIMIT;

	const chunks = needsTwoChunks
		? // Split into two balanced chunks
			menuItems
				.slice(0, totalItems)
				.reduce<SmallMenuItem[][]>((acc, item, index) => {
					const itemsPerChunk = Math.ceil(totalItems / 2);
					const chunkIndex = Math.floor(index / itemsPerChunk);
					if (!acc[chunkIndex]) {
						acc[chunkIndex] = [];
					}
					acc[chunkIndex].push(item);
					return acc;
				}, [])
		: // Keep as single chunk
			[menuItems.slice(0, totalItems)];

	if (needsTwoChunks && postsLists.length > 1) {
		postsLists = postsLists.slice(0, 1);
	}

	const needsLabelMargin =
		postsLists.length > 1 &&
		new Set(
			postsLists.map(
				(p) => Boolean(p?.children[0]?.title) && !p?.children[0]?.hide_label,
			),
		).size === 1;
	const renderLinkWrapper =
		isSmallMenu || !isMoreButton || isValidUrl(link.url);

	return (
		<li
			className={clsx(styles.menuSectionsListItem, {
				[styles.menuSectionsListItemActive]: activeMenuItem === link.ID,
				[styles.menuSectionsListItemIsNoMoreButton]: isMoreButton,
			})}
			id={`menu-item-${link.ID}`}
			key={link.ID}
			onMouseEnter={() =>
				hasChildren && !isSmallMenu && toggleActiveMenu(link.ID, false)
			}
			onMouseLeave={() =>
				hasChildren && !isSmallMenu && toggleActiveMenu(-1, false)
			}
		>
			<div className={styles.menuSectionsListItemLinkWrapper}>
				{renderLinkWrapper && (
					<LinkWrapper
						className={clsx(styles.menuSectionsListItemLink, link.classes)}
						href={link.url}
						preventDefault={isSmallMenu && hasChildren}
						preventSamePath={isSmallMenu && hasChildren}
					>
						{link.icon && link.icon_display_primary && (
							<Image
								alt=""
								className={styles.menuSectionsListItemLinkIcon}
								height={20}
								priority
								src={`${link.icon && link.icon ? link.icon : ''}`}
								width={20}
							/>
						)}
						{link.title}
					</LinkWrapper>
				)}
				{isMoreButton && !isValidUrl(link.url) && !isSmallMenu && (
					<span className={clsx(styles.menuSectionsListItemLink, link.classes)}>
						{link.title}
					</span>
				)}

				{hasChildren && (
					<button
						aria-label={`Toggle Submenu for ${link.title}`}
						onClick={() => toggleActiveMenu(link.ID, false)}
						type="button"
					>
						{/*  TODO: Update the "More" icon to KebabMore once the new version of the package is updated */}
						{isMoreButton && !isSmallMenu ? (
							<More height={12} width={12} />
						) : (
							<CaretOpen height={12} width={12} />
						)}
					</button>
				)}
			</div>

			{hasChildren && (
				<div
					aria-hidden={activeMenuItem !== link.ID}
					className={clsx(styles.menuSectionsListItemSubmenu, 'menu-wrapper')}
				>
					<button
						className={clsx(styles.menuSectionsListItemLink, link.classes)}
						onClick={() => toggleActiveMenu(link.ID, true)}
						type="button"
					>
						<Caret />
						{link.title}
					</button>

					<nav
						aria-label={`Sub Navigation for ${link.title}`}
						className={styles.menuSectionsListItemSubmenuContainer}
					>
						{chunks.map((chunk, i) => {
							const key = `chunk-${i}`;

							return (
								<div key={key}>
									{chunk.map((child) => (
										<LinkWrapper
											className={styles.menuSectionsListItemSubmenuLink}
											href={child.url}
											key={child.id}
										>
											{child.text}
										</LinkWrapper>
									))}
								</div>
							);
						})}
					</nav>
					{postsLists.map((postsList) => (
						<MegaMenuPostsList
							key={postsList.ID}
							link={postsList}
							marginLabel={needsLabelMargin}
						/>
					))}
				</div>
			)}
		</li>
	);
};
