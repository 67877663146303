import * as Icons from '@themaven-net/raven-assets';
import type { JSX, SVGProps, ComponentType } from 'react';

export type RavenIcon = keyof typeof Icons;

export const getIconComponent = (
	iconName: RavenIcon,
	size: number = 24,
): JSX.Element | null => {
	if (!iconName) {
		return null;
	}

	const IconComponent = (
		Icons as Record<string, ComponentType<SVGProps<SVGSVGElement>>>
	)[iconName];

	if (!IconComponent) {
		return null;
	}

	return <IconComponent height={size} width={size} />;
};
