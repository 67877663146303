'use client';

import { clsx } from 'clsx';
import CaretOpen from '@themaven-net/raven-assets/CaretOpen';
import {
	useId,
	useRef,
	type FC,
	useState,
	type PropsWithChildren,
} from 'react';

import styles from './styles.module.css';

interface BaseAccordionProps extends PropsWithChildren {
	buttonClassName?: string;
	buttonLabel: string;
	defaultOpen?: boolean;
	isOpen?: boolean;
	panelClassName?: string;
}

interface ControlledAccordionProps extends BaseAccordionProps {
	isOpen: boolean;
	onToggle: (newState: boolean) => void;
}

export interface UncontrolledAccordionProps extends BaseAccordionProps {
	isOpen?: undefined;
	onToggle?: (newState: boolean) => void;
}

type AccordionProps = ControlledAccordionProps | UncontrolledAccordionProps;

export const Accordion: FC<AccordionProps> = ({
	buttonClassName,
	buttonLabel,
	children,
	defaultOpen = false,
	isOpen,
	onToggle,
	panelClassName,
}) => {
	const isControlled = isOpen !== undefined;
	const status = isControlled ? isOpen : defaultOpen;
	const [internalOpen, setInternalOpen] = useState(status);
	const open = isControlled ? isOpen! : internalOpen;
	const ref = useRef<HTMLDivElement>(null);
	const accordionId = useId();
	const label = open ? `Close ${buttonLabel}` : `Open ${buttonLabel}`;

	const handleToggle = () => {
		if (ref.current) {
			ref.current.style.setProperty(
				'--max-height',
				`${ref.current.scrollHeight}px`,
			);
		}

		if (isControlled) {
			if (onToggle) {
				onToggle(!open);
			}
		} else {
			setInternalOpen((prev) => {
				const newState = !prev;

				if (onToggle) {
					onToggle(newState);
				}

				return newState;
			});
		}
	};

	return (
		<>
			<button
				aria-controls={accordionId}
				aria-expanded={open}
				aria-label={label}
				className={clsx(
					'accordion__button',
					styles.accordionButton,
					buttonClassName,
				)}
				onClick={handleToggle}
				type="button"
			>
				{buttonLabel}
				<CaretOpen height={12} width={12} />
			</button>
			<div
				aria-hidden={!open}
				className={clsx(
					'accordion__panel',
					styles.accordionPanel,
					panelClassName,
				)}
				ref={ref}
			>
				{children}
			</div>
		</>
	);
};
