import { decodeHTML } from 'entities';
import type { PostLookup } from '@/types/entities';

export function decodeEntities(text: string | undefined) {
	if (!text) {
		return '';
	}

	return decodeHTML(text);
}

export function getPostLookupParams(
	obj: PostLookup,
	requestedPage?: null | number,
): PostLookup {
	const pagination = obj.pagination || false;
	const page = pagination ? requestedPage || 1 : 1;

	return {
		numberOfPosts: obj.numberOfPosts,
		page,
		pagination,
		postType: obj.postType,
		taxonomy: obj.taxonomy,
		terms: obj.terms,
	};
}

export function serializePostLookup(
	lookup: PostLookup,
	requestedPage?: null | number,
): string {
	return JSON.stringify(getPostLookupParams(lookup, requestedPage));
}
