'use client';

import type { FC } from 'react';
import { Image } from '@/components/raven/Image';
import { useAppSettings } from '@/context/AppSettingsContext';

interface ArticleFeaturedImageProps {
	alt: string;
	aspectRatio?: `${number}:${number}`;
	className?: string;
	fill?: boolean;
	focalX?: number;
	focalY?: number;
	priority?: boolean;
	sizes: string;
	src?: string;
}

export const ArticleFeaturedImage: FC<ArticleFeaturedImageProps> = ({
	aspectRatio,
	className,
	src,
	...props
}) => {
	const { settings } = useAppSettings();
	const placeholder = settings.fallback_image;

	if (!src && !placeholder) {
		return null;
	}

	return src ? (
		<Image
			{...props}
			alt={props.alt ?? ''}
			aspectRatio={aspectRatio}
			className={className}
			src={src}
		/>
	) : (
		<Image
			alt=""
			aspectRatio={aspectRatio}
			className={className}
			fill
			src={placeholder}
			unoptimized
		/>
	);
};
