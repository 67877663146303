import { clsx } from 'clsx';
import type { MenuItemEntity } from '@/types/entities';
import { ArticleTile } from '@/components/ArticleTile';

import styles from './styles.module.css';

interface MegaMenuPostsListProps {
	link: MenuItemEntity;
	marginLabel?: boolean;
}

export const MegaMenuPostsList = ({
	link,
	marginLabel,
}: MegaMenuPostsListProps) => {
	const postsList = link.children.find(
		(child) => child.menu_item_type === 'postslist',
	);

	if (!postsList || !postsList.children || !postsList.children.length) {
		return null;
	}

	const label = postsList.title;
	const hasLabel = Boolean(label) && !postsList.hide_label;

	return (
		<div
			className={clsx(styles.menuSectionsListItemSubmenuFeatured, {
				[styles.menuSectionsListItemSubmenuFeaturedMargin]:
					marginLabel && !hasLabel,
			})}
		>
			{hasLabel && (
				<p className={styles.menuSectionsListItemSubmenuHeading}>{label}</p>
			)}
			{postsList.children.map((child, i) => {
				const key = `${child.ID}-${i}`;

				return (
					<ArticleTile
						cta={{
							opensInNewTab: false,
							title: 'Read More',
							url: child.link ?? '',
						}}
						image={
							child.featured_image
								? {
										alt: child.title,
										focalX: child.focal_point?.x,
										focalY: child.focal_point?.y,
										src: child.featured_image,
									}
								: undefined
						}
						key={key}
						link={child.link ?? ''}
						title={child.title}
						titleTag="p"
						variation="compact"
					/>
				);
			})}
		</div>
	);
};
