import { useRef } from 'react';
import { isClient } from '@/utils/is-client';
import { getPosition } from '@/utils/scroll-helpers';

import { useSafeLayoutEffect } from './use-safe-layout-effect';

/**
 * @name useScroll
 * @description Custom hook to run an effect once the user scrolls through the page
 * @param  effect - Effect that should be run on scroll, provides { prevPos, currPos } so
 *                it can be used to determine scroll direction.
 * @param  deps   - Dependency array to re-bind if needed
 * @param  wait   - Debounce amount
 */
export function useScroll(
	effect: (positions: { currPos: number; prevPos: number }) => void,
	deps: [],
	wait?: number,
) {
	const position = useRef(getPosition());
	const throttleTimeout = useRef<number>();

	const callBack = () => {
		const currPos = getPosition();
		effect({ currPos, prevPos: position.current });
		position.current = currPos;
		throttleTimeout.current = undefined;
	};

	useSafeLayoutEffect(() => {
		if (!isClient) {
			// We don't care if it's not browser, need to disable ESLint
			return;
		}

		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout.current === null) {
					throttleTimeout.current = setTimeout(
						callBack,
						wait,
					) as unknown as number;
				}
			} else {
				callBack();
			}
		};

		window.addEventListener('scroll', handleScroll, {
			capture: false,
			passive: true,
		});

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener('scroll', handleScroll);

			if (throttleTimeout.current) {
				clearTimeout(throttleTimeout.current);
			}
		};
	}, deps);
}
