import clsx from 'clsx';
import type { FC } from 'react';
import { Cta } from '@/components/raven/Cta';
import { HtmlDecoder } from '@headstartwp/core/react';
import { decodeEntities } from '@/utils/post-helpers';
import { LinkWrapper } from '@/components/LinkWrapper';
import { InternalLink } from '@/components/InternalLink';
import { ArticleCategory } from '@/components/ArticleCategory';
import { ArticleDate } from '@/components/raven/content/ArticleDate';
import { ArticleFeaturedImage } from '@/components/ArticleFeaturedImage';
import type { ArticleTileProps, ArticleTileVariation } from '@/types/articles';

import styles from './styles.module.css';

const ImageSizes: Record<ArticleTileVariation, string> = {
	category: ['(min-width: 768px) calc(16.6vw - 90px)', '300px'].join(', '),
	compact: '100px',
	gridwall: [
		'(min-width: 1024px) 325px',
		'(min-width: 768px) 230px',
		'calc(50vw - 36px)',
	].join(', '),
	horizontal: ['(min-width: 768px) 180px', '146px'].join(', '),
	stacked: [
		'(min-width: 914px) 165px',
		'(min-width: 768px) calc(25vw - 90px)',
		'calc(50vw - 90px)',
	].join(', '),
	wide: ['(min-width: 768px) 202px', '128px'].join(', '),
};

const AspectRatios: Record<ArticleTileVariation, `${number}:${number}`> = {
	category: '1:1',
	compact: '1:1',
	gridwall: '4:3',
	horizontal: '1:1',
	stacked: '4:3',
	wide: '16:9',
};

export const ArticleTile: FC<ArticleTileProps> = ({
	addStroke,
	aspectRatio,
	author_name,
	category,
	cta,
	date,
	description,
	hideAuthor,
	hideCategory,
	hideDate,
	hideTags,
	image,
	link,
	tags,
	title,
	titleTag = 'h3',
	variation = 'stacked',
}) => {
	const cx = clsx(styles.articleTile, {
		[styles.articleTileCategoryVariation]: variation === 'category',
		[styles.articleTileCompact]: variation === 'compact',
		[styles.articleTileGridwall]: variation === 'gridwall',
		[styles.articleTileHorizontal]: variation === 'horizontal',
		[styles.articleTileWide]: variation === 'wide',
	});
	const imageWrapperCx = clsx(styles.articleTileImageWrapper, {
		[styles.articleTileImageWrapper11]: aspectRatio === '1-1',
		[styles.articleTileImageWrapper169]: aspectRatio === '16-9',
		[styles.articleTileImageWrapper43]: aspectRatio === '4-3',
		[styles.articleTileImageWrapper45]: aspectRatio === '4-5',
		[styles.articleTileImageWrapperStroke]: addStroke,
	});
	const imageSize = ImageSizes[variation];
	const TitleTag = titleTag;
	const showMetaCat =
		(tags && tags.length > 0 && !hideTags) || (category && !hideCategory);
	const showMeta = (author_name && !hideAuthor) || (date && !hideDate);

	return (
		<article className={cx}>
			<figure className={imageWrapperCx}>
				<ArticleFeaturedImage
					alt={image?.alt ?? ''}
					aspectRatio={AspectRatios[variation]}
					className={styles.articleTileImage}
					fill
					focalX={image?.focalX}
					focalY={image?.focalY}
					sizes={imageSize}
					src={`${image ? image?.src : ''}`}
				/>
			</figure>

			<div className={styles.articleTileContent}>
				{showMetaCat && (
					<div className={styles.articleTileMetaCat}>
						{tags && tags.length > 0 && !hideTags && (
							<ul className={styles.articleTileTags}>
								{tags.map((tag) => (
									<li key={tag}>{tag}</li>
								))}
							</ul>
						)}
						{category && !hideCategory && (
							<ArticleCategory
								category={category}
								className={styles.articleTileCategory}
							/>
						)}
					</div>
				)}
				<TitleTag className={styles.articleTileTitle}>
					<InternalLink className={styles.articleTileLink} href={link}>
						<HtmlDecoder html={decodeEntities(title ?? '')} />
					</InternalLink>
				</TitleTag>
				{showMeta && (
					<div className={styles.articleTileMeta}>
						{author_name && !hideAuthor && (
							<div className={styles.articleTileAuthor}>
								{decodeEntities(author_name)}
							</div>
						)}
						{date && !hideDate && (
							<ArticleDate className={styles.articleTileDate} date={date} />
						)}
					</div>
				)}
				{description && (
					<p className={styles.articleTileDescription}>
						<HtmlDecoder html={description} />
					</p>
				)}
				{cta && variation === 'compact' ? (
					<LinkWrapper
						ariaHidden
						ariaLabel={`Read More: ${title ?? ''}`}
						className={styles.articleTileCta}
						href={cta.url}
						tabIndex={-1}
						target={cta.opensInNewTab ? '_blank' : undefined}
					>
						{cta.title}
					</LinkWrapper>
				) : (
					cta && <Cta cta={cta} linkClassName={styles.articleTileCta} />
				)}
			</div>
		</article>
	);
};
