import { getPosition } from '@/utils/scroll-helpers';
import { useScroll } from '@/utils/hooks/use-scroll';
import type { ScrollHandlerProps } from '@/types/scroll-handler';
import { useRef, useState, useEffect, useCallback } from 'react';

/**
 * @name useSticky
 * @description Custom hook to make an element sticky on scroll and add class when stuck
 */
export const useSticky = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [isSticky, setIsSticky] = useState(false);

	const handleScroll = useCallback(({ currPos }: ScrollHandlerProps) => {
		if (!ref.current) {
			return;
		}

		const stickyElementTop = ref.current.offsetTop;
		const currentTop = ref.current.getBoundingClientRect().top;

		setIsSticky(currentTop <= stickyElementTop && currPos > 0);
	}, []);

	useScroll(handleScroll, []);
	useEffect(() => {
		const currPos = getPosition();
		handleScroll({ currPos, prevPos: 0 });

		if (ref.current) {
			document.documentElement.style.setProperty(
				'--scroll-padding',
				`${ref.current.offsetHeight}px`,
			);
		}
	}, [handleScroll]);

	return { isSticky, ref };
};
