import type { WPImage } from '@/types/entities';
import type { Element } from 'html-react-parser';
import { safeJSONParse } from '@/utils/json-parse';
import { Children, isValidElement, type ReactNode } from 'react';
import {
	type LinkEmbedConfig,
	isCompleteCommerceItem,
} from '@themaven-net/commerce-shared';

import type { ImageBlockAttributes } from '../components/raven/Blocks/ImageBlock';

export interface SwipeGalleryImage extends WPImage {
	commerceConfig?: LinkEmbedConfig;
	hasCommerceLink: boolean;
	linkClass: string | undefined;
	linkTarget: string | undefined;
	linkTo: string | undefined;
	rel: string | undefined;
}

export const getImage = (domNode: Element) => {
	const imageElement = domNode.children[0] as Element;
	const {
		commerceConfig,
		hasCommerceLink,
		height,
		id,
		linkDestination,
		width,
	} = safeJSONParse(domNode.attribs['data-wp-block']) as ImageBlockAttributes;

	let imageAttributes;
	// TODO "linkDestination":"none" for commerce links. Why?
	if (linkDestination === 'none' && !hasCommerceLink) {
		imageAttributes = imageElement.attribs;
	} else if (
		(linkDestination !== 'none' || hasCommerceLink) &&
		imageElement.children.length !== 0
	) {
		imageAttributes = (imageElement.children[0] as Element).attribs;
	} else {
		imageAttributes = imageElement.attribs;
	}

	let href = linkDestination === 'none' ? '' : imageElement.attribs.href;

	// We have a manually passed in commerce link
	// So no offer or commerce item data is available
	// Just pass the commerce link through
	if (
		hasCommerceLink &&
		commerceConfig?.offer === undefined &&
		!isCompleteCommerceItem(commerceConfig?.item)
	) {
		href = imageElement.attribs.href;
	}
	const target = linkDestination === 'none' ? '' : imageElement.attribs.target;
	const rel = target === '_blank' ? imageElement.attribs.rel : '';

	const h = typeof height === 'string' ? Number(height.replace('px', '')) : 0;
	const w = typeof width === 'string' ? Number(width.replace('px', '')) : 0;

	return {
		alt: imageAttributes.alt || '',
		commerceConfig,
		hasCommerceLink,
		height: h,
		href,
		id: String(id),
		linkClass: imageElement.attribs.class || '',
		linkTo: linkDestination,
		rel,
		src: imageAttributes.src,
		target,
		width: w,
	};
};

const getSize = (sizeString?: string, sizeNumber?: unknown): number => {
	if (sizeNumber) {
		return Number(sizeNumber) || 0;
	}
	if (sizeString) {
		return Number(sizeString.replace('px', '')) || 0;
	}
	return 0;
};

export const getImages = (
	domNode: Element,
	randomOrder: boolean,
): SwipeGalleryImage[] => {
	const rawImages = domNode.children.filter((node) => node.type === 'tag');
	const images: SwipeGalleryImage[] = [];

	rawImages.forEach((image) => {
		const imgEl = image as Element;

		const {
			commerceConfig,
			focalPoint,
			hasCommerceLink,
			height: heightPx,
			id,
			linkDestination,
			sourceName,
			sourceUrl,
			width: widthPx,
		} = safeJSONParse(imgEl.attribs['data-wp-block']);
		let imageAttributes;
		// TODO "linkDestination":"none" for commerce links. Why?
		let imageLinkAttributes;
		if (
			linkDestination === 'none' &&
			!hasCommerceLink &&
			imgEl?.children?.length > 0
		) {
			imageAttributes = (imgEl.children[0] as Element).attribs;
		} else if (
			(linkDestination !== 'none' || hasCommerceLink) &&
			imgEl?.children?.length > 0 &&
			(imgEl.children[0] as Element)?.children?.length > 0
		) {
			imageLinkAttributes = (imgEl.children[0] as Element).attribs;
			imageAttributes = ((imgEl.children[0] as Element).children[0] as Element)
				.attribs;
		} else {
			imageAttributes = (imgEl.children[0] as Element).attribs;
		}

		let href = linkDestination === 'none' ? '' : imageLinkAttributes?.href;
		// We have a manually passed in commerce link
		// So no offer or commerce item data is available
		// Just pass the commerce link through
		if (
			hasCommerceLink &&
			commerceConfig?.offer === undefined &&
			!isCompleteCommerceItem(commerceConfig?.item)
		) {
			href = imageLinkAttributes?.href;
		}
		const target =
			linkDestination === 'none' ? '' : imageLinkAttributes?.target;
		const rel = target === '_blank' ? imageLinkAttributes?.rel : '';
		const height = getSize(heightPx, imageAttributes.height);
		const width = getSize(widthPx, imageAttributes.width);
		let focalPointObject: { x: number; y: number } | undefined;
		let focalPointX: number;
		let focalPointY: number;

		if (focalPoint?.x && focalPoint?.y && width && height) {
			focalPointX = (focalPoint.x / width) * 100;
			focalPointY = (focalPoint.y / height) * 100;

			focalPointObject = {
				x: focalPointX,
				y: focalPointY,
			};
		}

		const img = {
			alt_text: imageAttributes.alt,
			caption: {
				rendered: imgEl?.children?.[1]
					? (
							(imgEl?.children?.[1] as Element)
								?.children?.[0] as unknown as Text
						)?.data
					: '',
			},
			commerceConfig,
			hasCommerceLink,
			id,
			img_src_name: sourceName || '',
			img_src_url: sourceUrl || '',
			link: href,
			linkTarget: target,
			linkTo: linkDestination,
			media_details: {
				height,
				width,
			},
			meta: {
				focal_point: focalPointObject,
			},
			rel,
			slug: '',
			source_url: imageAttributes.src,
		} as unknown as SwipeGalleryImage;
		images.push(img);
	});
	if (randomOrder) {
		images.sort(() => Math.random() - 0.5);
	}
	return images;
};

export const extractCaptions = (children: ReactNode) => {
	const imageSrcToCaptionReactNodes: Record<string, ReactNode> = {};
	Children.forEach(children, (child) => {
		if (!isValidElement(child)) {
			return;
		}
		let propsChildren = child?.props?.children;
		if (!Array.isArray(propsChildren)) {
			propsChildren = [propsChildren];
		}
		const [firstChild, secondChild] = propsChildren;
		let src;

		if (firstChild?.type === 'img' && firstChild.props?.src) {
			src = firstChild.props?.src;
		} else if (
			firstChild?.type === 'a' &&
			firstChild.props?.children?.type === 'img' &&
			firstChild.props.children.props?.src
		) {
			src = firstChild.props.children.props?.src;
		}

		if (
			src &&
			secondChild?.type === 'figcaption' &&
			secondChild.props?.children
		) {
			imageSrcToCaptionReactNodes[src] = secondChild.props?.children;
		}
	});
	return imageSrcToCaptionReactNodes;
};

export const extractMediaCarouselCaptions = (children: ReactNode) => {
	const imageNodes: ReactNode[] = [];
	Children.forEach(children, (child) => {
		if (!isValidElement(child)) {
			return;
		}
		if (
			typeof child?.type === 'function' &&
			child?.props?.classList?.includes('wp-block-gallery')
		) {
			imageNodes.push(child.props.children);
		}
	});
	// Ideally only a single gallery should exist inside a Media Carousel.
	return extractCaptions(imageNodes[0]);
};

export const isSvg = (src: string) => src.split('.').pop() === 'svg';
