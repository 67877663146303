import type { MenuItemEntity } from '@/types/entities';
import type { MenuAttributes } from '@/components/raven/global/SubNavigation';

const defaultSubMenu: MenuAttributes = {
	icon: undefined,
	menuItems: [],
	title: '',
	url: '',
};

export const isOrEndsWith = (maybeUrl: string, path: string) => {
	const [firstSegment] = path.split('/').filter(Boolean);
	if (maybeUrl.includes(`/${firstSegment}/`)) {
		return true;
	}

	try {
		const url = new URL(maybeUrl);
		return url.pathname === path || url.pathname.endsWith(path);
	} catch {
		return maybeUrl.endsWith(path);
	}
};

export const generateSubmenu = (
	menuItems: MenuItemEntity[],
): MenuAttributes[] => {
	if (!menuItems || !menuItems.length) {
		return [];
	}

	// Filter the valid menu items.
	const mainNavigationLinks = menuItems.filter(
		(link) => link.children.length > 0 && link.url !== '#',
	);

	// Generate an array of menu items.
	return mainNavigationLinks.map((menuItem) => {
		const showSecondaryIcon = menuItem.icon_display_secondary;
		const menuIcon = showSecondaryIcon ? menuItem.icon : undefined;
		const menuTitle = menuItem.title;
		const menuUrl = menuItem.url;
		const subMenuItems = menuItem.children.flatMap((link) => {
			if (link.menu_item_type === 'postslist') {
				return [];
			}

			if (link.menu_item_type === 'group') {
				return link.children
					.filter((groupChild) => groupChild.menu_item_type !== 'postslist')
					.map((groupChild) => ({
						id: groupChild.ID,
						text: groupChild.title,
						url: groupChild.url,
					}));
			}

			return [
				{
					id: link.ID,
					text: link.title,
					url: link.url,
				},
			];
		});

		return {
			icon: menuIcon,
			menuItems: subMenuItems,
			title: menuTitle,
			url: menuUrl,
		};
	});
};

export const selectSubNavigation = (
	subNavigation: MenuAttributes[],
	pathname: string,
	lastVisitedPath: string,
): MenuAttributes => {
	// If the current path is the root path and the last visited path is also the root path,
	// return the default sub menu.
	if (pathname === '/' && (!lastVisitedPath || lastVisitedPath === '/')) {
		return defaultSubMenu;
	}

	// If the current path is the root path and the last visited path is not the root path,
	// return the sub menu that matches the last visited path.
	const selectedMenu = subNavigation.find((menu) => {
		return (
			isOrEndsWith(menu.url, pathname) ||
			menu.menuItems.some((menuItem) => isOrEndsWith(menuItem.url, pathname))
		);
	});

	if (selectedMenu) {
		return selectedMenu;
	}

	// If the current path is not the root path, return the sub menu that matches the current path.
	if (lastVisitedPath && lastVisitedPath !== '/') {
		const previousMenu = subNavigation.find((menu) => {
			return (
				isOrEndsWith(menu.url, lastVisitedPath) ||
				menu.menuItems.some((menuItem) =>
					isOrEndsWith(menuItem.url, lastVisitedPath),
				)
			);
		});

		if (previousMenu) {
			return previousMenu;
		}
	}

	return defaultSubMenu;
};
