'use client';

import { isSvg } from '@/utils/image-utils';
import { type FC, useCallback } from 'react';
import NextImage, { type ImageProps, type ImageLoaderProps } from 'next/image';

interface ExtendedImageProps {
	aspectRatio?: `${number}:${number}`;
	focalX?: number;
	focalY?: number;
}

export type CloudinaryImageProps = ExtendedImageProps & ImageProps;
type LoaderProps = ExtendedImageProps & ImageLoaderProps;

type CustomImageLoader = (resolverProps: LoaderProps) => string;
/**
 * Custom Image loader for Cloudinary. This can't be made
 * a global loader because of the local loader used on the custom image component.
 */
const loader: CustomImageLoader = ({
	aspectRatio,
	focalX,
	focalY,
	quality,
	src,
	width,
}) => {
	const { hostname, pathname } = new URL(src);
	const isLocalDomain =
		hostname.endsWith('.localhost') || hostname.endsWith('.local');
	const isCDN = pathname.startsWith('/.image/');

	if (isLocalDomain) {
		return src;
	}

	if (!isLocalDomain && isCDN && !isSvg(src)) {
		const params = [`w_${width}`, `q_${quality || 'auto:good'}`];

		if (aspectRatio) {
			if (aspectRatio) {
				params.push('c_fill');
				params.push(`ar_${aspectRatio}`);
			}

			if (focalX && focalY) {
				params.push('g_xy_center');
				params.push(`x_${focalX}`);
				params.push(`y_${focalY}`);
			}
		} else {
			params.push('c_limit');
		}

		const imageUrl = src
			.split('/')
			.splice(-2)
			.map((part) => encodeURIComponent(part))
			.join('/');

		return `https://${hostname}/.image/${params.join(',')}/${imageUrl}`;
	}

	return src;
};

/**
 * This component wraps Next's image component to provide an image loader. An
 * image loader allows us to add query parameters for VIP's image processor.
 *
 * This uses a custom cached loader to be able to access the `aspectRatio` property. If
 * set, it will be used to calculate the height from the width so the image gets properly
 * cropped.
 *
 * Note that if this is a brand-new site instead of a migration it is best to just pass
 * the `loader` and remove the `internalLoader` fragment within this component to avoid
 * creating unnecessary callbacks per image.
 *
 * https://nextjs.org/docs/api-reference/next/image#loader
 * https://docs.wpvip.com/technical-references/vip-go-files-system/image-transformation/
 */
export const Image: FC<CloudinaryImageProps> = ({
	aspectRatio,
	focalX,
	focalY,
	...props
}) => {
	const internalLoader = useCallback(
		(loaderProps: { quality?: number; src: string; width: number }) =>
			loader({ ...loaderProps, aspectRatio, focalX, focalY }),
		[aspectRatio, focalX, focalY],
	);

	return <NextImage loader={internalLoader} {...props} />;
};
