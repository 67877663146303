import dayjs from 'dayjs';
import type { FC } from 'react';

interface ArticleDateProps {
	className?: string;
	date: string;
}

export const ArticleDate: FC<ArticleDateProps> = ({ className = '', date }) => (
	<time className={className} dateTime={date}>
		{dayjs(date).format('MMMM D, YYYY')}
	</time>
);
