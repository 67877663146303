import { Button } from '@/components/raven/ui/Button';
import Search from '@themaven-net/raven-assets/Search';

import styles from './styles.module.css';

export const SearchWidgetTrigger = () => {
	return (
		<Button
			className={styles.searchWidgetTrigger}
			id="searchWidgetTrigger"
			onClick={() => {}}
			variation="icon-small"
		>
			<Search />
		</Button>
	);
};
