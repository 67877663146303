'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { isSvg } from '@/utils/image-utils';
import { useKey } from '@/utils/hooks/use-key';
import type { WPImage } from '@/types/entities';
import { Image } from '@/components/raven/Image';
import { useRef, type FC, useEffect } from 'react';
import Close from '@themaven-net/raven-assets/Close';
import { MegaMenu } from '@/components/raven/MegaMenu';
import Newsletter from '@themaven-net/raven-assets/Newsletter';
import { useNavigationContext } from '@/context/NavigationContext';
import type { AppSettings } from '@/utils/data/fetch-app-settings';

import styles from './styles.module.css';

export interface NavigationProps {
	logo?: WPImage;
	menus: AppSettings['menus'];
}

export const Navigation: FC<NavigationProps> = ({ logo, menus }) => {
	const navigationRef = useRef<HTMLDivElement | null>(null);
	const newsletterButtonRef = useRef<HTMLDivElement | null>(null);
	const {
		handleSetActiveMenuItem,
		handleSetIsMenuOpen,
		handleSetIsNewsletterOpen,
		isMenuOpen,
	} = useNavigationContext();

	const hasPrimaryMenu = menus.primary && menus.primary.length > 0;

	useKey('Escape', () => handleSetActiveMenuItem(-1), navigationRef);

	// This will prevent the navigation from translating when resizing the window.
	useEffect(() => {
		let resizeTimer: NodeJS.Timeout;

		const handleResize = () => {
			if (navigationRef.current) {
				navigationRef.current.classList.add(styles.menuIsResizing);
			}

			clearTimeout(resizeTimer);

			resizeTimer = setTimeout(() => {
				if (navigationRef.current) {
					navigationRef.current.classList.remove(styles.menuIsResizing);
				}
			}, 400);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			clearTimeout(resizeTimer);
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (newsletterButtonRef.current) {
			const buttonHeight = newsletterButtonRef.current.offsetHeight;
			document.documentElement.style.setProperty(
				'--newsletter-button-height',
				`${buttonHeight}px`,
			);
		}
	}, []);

	const handleNewsletterOpen = () => {
		handleSetIsMenuOpen(false);
		handleSetIsNewsletterOpen(true);
	};

	return (
		<div
			className={clsx(styles.menuContainer, {
				[styles.menuContainerClose]: !isMenuOpen,
				[styles.menuContainerOpen]: isMenuOpen,
			})}
			id="header"
			ref={navigationRef}
		>
			<div className={styles.menuNavigationHeader}>
				{logo && (
					<Link
						aria-label="Go to Homepage"
						className={styles.menuNavigationHeaderLink}
						href="/"
					>
						<Image
							alt={logo.alt_text}
							height={logo.media_details.height}
							src={logo.source_url}
							unoptimized={isSvg(logo.source_url)}
							width={logo.media_details.width}
						/>
					</Link>
				)}

				<button
					aria-controls="header"
					aria-expanded={isMenuOpen}
					aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
					className={styles.menuNavigationCloseButton}
					onClick={() => handleSetIsMenuOpen(false)}
					type="button"
				>
					<Close height={20} width={20} />
				</button>
			</div>

			<div className={styles.menuNavigation}>
				{hasPrimaryMenu && (
					<MegaMenu ariaLabel="Primary Menu" links={menus.primary} />
				)}
			</div>

			<div className={styles.menuNavigationFooter} ref={newsletterButtonRef}>
				<button
					className={clsx(
						'button button--primary',
						styles.menuNavigationFooterNewsletterButton,
					)}
					onClick={handleNewsletterOpen}
					type="button"
				>
					Get Our Newsletter
					<Newsletter height={12} width={12} />
				</button>
			</div>
		</div>
	);
};
