import clsx from 'clsx';
import type { FC } from 'react';
import type { MenuItemEntity } from '@/types/entities';
import { SocialIcon } from '@/components/raven/global/SocialIcon';
import { capitalizeFirstLetter } from '@/utils/capitalize-first-letter';

import styles from './styles.module.css';

interface SocialNetworksProps {
	ariaLabel?: string;
	networks?: MenuItemEntity[];
}

export const SocialNetworks: FC<SocialNetworksProps> = ({
	ariaLabel,
	networks,
}) => {
	if (!networks || networks.length === 0) {
		return null;
	}

	return (
		<ul className={clsx(styles.networksList)}>
			{networks.map(({ slug, url }) => (
				<li key={slug}>
					<SocialIcon
						ariaLabel={ariaLabel?.replace('%s', capitalizeFirstLetter(slug))}
						network={slug}
						url={url}
					/>
				</li>
			))}
		</ul>
	);
};
