import clsx from 'clsx';
import Link from 'next/link';
import type { FC } from 'react';
import type { CtaProps } from '@/types/cta';
import { HtmlDecoder } from '@headstartwp/core/react';
import { type RavenIcon, getIconComponent } from '@/utils/get-icons';

interface CtaComponentProps {
	className?: string;
	cta: CtaProps;
	icon?: RavenIcon;
	linkClassName?: string;
	variation?: 'link' | 'primary' | 'secondary';
}

export const Cta: FC<CtaComponentProps> = ({
	className,
	cta,
	icon,
	linkClassName,
	variation,
}) => {
	const { opensInNewTab, title, url } = cta;

	if (!url || (!title && !icon)) {
		return null;
	}

	const LinkType = url.startsWith('#') ? 'a' : Link;
	const cx = clsx('button', linkClassName, {
		'button--link': variation === 'link',
		'button--primary': variation === 'primary',
		'button--secondary': variation === 'secondary',
	});

	return (
		<div className={className}>
			<LinkType
				aria-label={!title && icon ? `Click ${icon} icon` : undefined}
				className={cx}
				href={url}
				target={opensInNewTab ? '_blank' : '_self'}
			>
				{title && <HtmlDecoder html={title} />}
				{icon && getIconComponent(icon, 12)}
			</LinkType>
		</div>
	);
};
