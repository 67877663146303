'use client';

import { isClient } from '@/utils/is-client';
import { usePathname } from 'next/navigation';
import { isFileLink } from '@/utils/is-file-link';
import type { FC, PropsWithChildren } from 'react';
import { decodeEntities } from '@/utils/post-helpers';
import { InternalLink } from '@/components/InternalLink';
import { useNavigationContext } from '@/context/NavigationContext';

interface CustomLinkProps extends PropsWithChildren {
	ariaHidden?: boolean;
	ariaLabel?: string;
	className?: string;
	href: string;
	preventDefault?: boolean;
	preventSamePath?: boolean;
	tabIndex?: number;
	target?: string;
}

export const LinkWrapper: FC<CustomLinkProps> = ({
	ariaHidden,
	ariaLabel,
	children,
	className,
	href,
	preventDefault,
	preventSamePath,
	tabIndex,
	target,
}) => {
	const pathname = usePathname();
	const { handleSetActiveMenuItem, handleSetIsMenuOpen } =
		useNavigationContext();

	const isMailTo = href.startsWith('mailto:');
	const isFile = isFileLink(href);
	const isValidURL = /^https?:\/\//.test(href) || href === '/';

	const LinkComponent = isMailTo ? 'a' : InternalLink;
	const prefetch = isFile ? false : undefined;

	const link =
		isClient && isValidURL ? new URL(href, window.location.origin) : null;

	const hasSamePath =
		!isMailTo && !isFile && link && pathname === link.pathname;

	const handleClick = (event: { preventDefault: () => void }) => {
		// Close the menu when preventSamePath is false and the user clicks on a link with the same path.
		if (!preventSamePath && target !== '_blank' && hasSamePath) {
			handleSetIsMenuOpen(false);
			handleSetActiveMenuItem(-1);
		}

		// Check preventDefault to prevent the default behavior of the event.
		if (preventDefault) {
			event.preventDefault();
		}
	};

	return (
		<LinkComponent
			aria-hidden={ariaHidden}
			aria-label={ariaLabel ? decodeEntities(ariaLabel) : undefined}
			className={className}
			href={href}
			onClick={handleClick}
			prefetch={prefetch}
			tabIndex={tabIndex}
			target={target}
		>
			{children}
		</LinkComponent>
	);
};
