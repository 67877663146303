'use client';

import { clsx } from 'clsx';
import { useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { NAV_STICKY_ENABLE } from '@/utils/events';
import { useSticky } from '@/utils/hooks/use-sticky';
import { useEventsByType } from '@/utils/hooks/use-events';

import styles from './styles.module.css';

interface StickyProps extends PropsWithChildren {
	bottom?: number;
	top?: number;
}

type StickyStyles = {
	bottom?: string;
	top?: string;
};

export const Sticky: FC<StickyProps> = ({ bottom, children, top = 0 }) => {
	const { isSticky, ref } = useSticky();
	const [isEnabled, setEnabled] = useState(true);
	const isEnabledAndSticky = isEnabled && isSticky;
	useEventsByType(NAV_STICKY_ENABLE, ({ value }) => setEnabled(value));

	const cx = clsx('sticky-element', {
		stuck: isEnabledAndSticky,
		[styles.stickyElement]: isEnabled,
		[styles.stickyElementStuck]: isEnabledAndSticky,
	});

	const style: StickyStyles = {};
	if (bottom !== undefined) {
		style.bottom = `${bottom}px`;
	} else {
		style.top = `${top}px`;
	}

	return (
		<div className={cx} ref={ref} style={style}>
			{children}
		</div>
	);
};
