import { useMemo, type RefObject } from 'react';
import { useEvent } from '@/utils/hooks/use-event';

/**
 * @name createMatchesKey
 * @description normalizes the keys that the useKey hook can handle allowing for different options
 * @param {string|Function|*} key - the key being evaluated which can be of different types for flexibility.
 * If it's a string it will be compared against the event's key property to see if it matches.
 * If it's a function it will be run with the event for the hook's usage to decide.
 * If it's any other type it'll evaluate falsy / truthy to decide.
 * @return {(function(*): boolean)} Returns a function that determines if the event is applicable or not
 */
const createMatchesKey = (
	key: ((event: KeyboardEvent) => boolean) | string | unknown,
) => {
	if (typeof key === 'function') {
		return key;
	}

	if (typeof key === 'string') {
		return (event: KeyboardEvent) => event.key === key;
	}

	return key ? () => true : () => false;
};

/**
 * @name useKey
 *
 * convenient wrapper around useEvent to handle key presses allowing to send either the key being watched, a function
 * to evaluate or any truthy/falsy value
 * @param {string|Function|*} key     - the key being evaluated
 * @param {Function}          handler - handler function to be called if the key matches
 * @param {Object}            ref     - reference from useRef containing a DOM node
 * @see createMatchesKey
 * @see useEvent
 */
export function useKey(
	key: ((event: Event) => boolean) | string | unknown,
	handler: (event: KeyboardEvent) => void,
	ref: RefObject<HTMLElement>,
) {
	const useMemoHandler = useMemo(() => {
		const fn = createMatchesKey(key as string);

		return (handlerEvent: Event) => {
			if (fn(handlerEvent as KeyboardEvent)) {
				handler(handlerEvent as KeyboardEvent);
			}
		};
	}, [key, handler]);

	useEvent(ref, 'keydown', useMemoHandler);
}
