'use client';

import { clsx } from 'clsx';
import { useRef, type FC } from 'react';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import { useNavigationContext } from '@/context/NavigationContext';

import styles from './styles.module.css';
import { Newsletter } from '../../Newsletter';

export interface NewsletterDropdownProps {
	settings: Pick<AppSettings['settings'], 'copyright'>;
}

export const NewsletterDropdown: FC<NewsletterDropdownProps> = ({
	settings,
}) => {
	const newsletterRef = useRef<HTMLDivElement | null>(null);
	const { isNewsletterOpen } = useNavigationContext();

	return (
		<div
			className={clsx(
				styles.newsletterDropdown,
				isNewsletterOpen ? styles.newsletterDropdownActive : '',
			)}
			ref={newsletterRef}
		>
			<Newsletter placement="header" />
		</div>
	);
};
