import { isClient } from '@/utils/is-client';

export const elInView = (
	el: HTMLElement,
	updateOnce: boolean = true,
	offset: number = 0.5,
) => {
	if (!el || !('classList' in el)) {
		return false;
	}

	if (updateOnce && el.classList.contains('-viewed')) {
		return false;
	}

	const elTop = el.getBoundingClientRect().top;
	const windowHeight =
		window.innerHeight || document.documentElement.clientHeight;
	const newWindowHeight = windowHeight * offset;
	const isInView = elTop < newWindowHeight;

	if (updateOnce && isInView) {
		el.classList.add('-viewed');
	}

	return isInView;
};

export function getPosition() {
	const doc = isClient ? (document as unknown as HTMLElement) : null;

	return isClient && doc
		? (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0) || 0
		: 0;
}

export function getElementCoordinates(
	el: HTMLElement,
	position = getPosition(),
) {
	if (!isClient || !el) {
		return {
			bottom: 0,
			height: 0,
			middle: 0,
			top: 0,
		};
	}

	const rect = el.getBoundingClientRect
		? el.getBoundingClientRect()
		: {
				height: 0,
				top: el.offsetTop,
			};

	const top = rect.top + position;

	return {
		bottom: top + rect.height,
		height: rect.height,
		middle: top + rect.height / 2,
		top,
	};
}
