'use client';

import clsx from 'clsx';
import Checkmark from '@themaven-net/raven-assets/Checkmark';
import { useRef, type FC, useEffect, type ReactNode } from 'react';

import styles from './styles.module.css';

interface CheckboxProps {
	checked?: boolean;
	className?: string;
	disabled?: boolean;
	id: string;
	indeterminate?: boolean;
	label: ReactNode;
	labelHidden?: boolean;
	name?: string;
	onChange?: () => void;
	required?: boolean;
	requiredErrorMessage?: string;
	value?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
	checked,
	className,
	disabled,
	id,
	indeterminate,
	label,
	labelHidden,
	name,
	onChange,
	required,
	requiredErrorMessage,
	value,
}) => {
	const checkboxRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (!required) {
			return;
		}
		checkboxRef.current?.setCustomValidity('');
		if (!checked && requiredErrorMessage) {
			checkboxRef.current?.setCustomValidity(requiredErrorMessage);
		}
	}, [checked, requiredErrorMessage, required]);

	useEffect(() => {
		if (indeterminate && checkboxRef.current) {
			checkboxRef.current.indeterminate = true;
		}
	}, [indeterminate]);

	if (!id || !label) {
		return null;
	}

	return (
		<div className={clsx(styles.checkbox, className)}>
			<label className={styles.checkboxWrapper} htmlFor={id}>
				<input
					checked={checked}
					className={styles.checkboxInput}
					disabled={disabled}
					id={id}
					name={name}
					onChange={onChange}
					ref={checkboxRef}
					required={required}
					type="checkbox"
					value={value}
				/>
				<span className={styles.checkboxControl}>
					<Checkmark className={styles.checkboxCheckmark} />
				</span>
				<span
					className={clsx(styles.checkboxLabel, {
						'visually-hidden': labelHidden,
					})}
				>
					{label}
				</span>
			</label>
		</div>
	);
};
