/**
 * The `isValidUrl` function checks if a given string is a valid URL
 * by attempting to create a new URL object from it.
 *
 * Special cases:
 * - The string `"#"` is commonly used as a placeholder and is **not** considered a valid URL.
 * - An empty string `""` is also **not** considered valid.
 *
 * @param {string} url - The URL string to validate.
 * @returns {boolean} - Returns `true` if the URL is valid and not a placeholder or empty; `false` otherwise.
 */
export const isValidUrl = (url: string): boolean => {
	const trimmedUrl = url.trim();

	try {
		// eslint-disable-next-line no-new
		new URL(trimmedUrl);
		return true;
	} catch (e) {
		return false;
	}
};
