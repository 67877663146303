'use client';

import clsx from 'clsx';
import Link from 'next/link';
import { slugify } from '@/utils/slugify';
import type { FC, CSSProperties } from 'react';
import { isFileLink } from '@/utils/is-file-link';
import type { MenuItemEntity } from '@/types/entities';
import { Accordion } from '@/components/raven/ui/Accordion';
import type { PrivacySettingsProps } from '@/components/PrivacySettings';

interface MenuProps {
	ariaLabel: string;
	className?: string;
	linkClassName?: string;
	links?: MenuItemEntity[];
	listItemClassName?: string;
	menuAccordion?: boolean;
	privacySettings?: FC<PrivacySettingsProps>;
}

const MenuLink: FC<{
	className?: string;
	link: MenuItemEntity;
}> = ({ className, link }) => {
	let linkedUrl = link.url ? link.url : '';

	// Set proper URL for 'page' or 'post' types
	if (link.object_id === 'page' || link.object_id === 'post') {
		try {
			const url = new URL(link.url);
			linkedUrl = `${url.pathname}`;
		} catch (error) {
			// error silently
		}
	}

	const LinkComponent = linkedUrl.startsWith('mailto:') ? 'a' : Link;
	const prefetch = isFileLink(linkedUrl) ? false : undefined;

	return (
		<LinkComponent
			className={clsx(className, link.classes)}
			href={linkedUrl}
			prefetch={prefetch}
			target={link.target || undefined}
		>
			{link.title}
		</LinkComponent>
	);
};

export const Menu: FC<MenuProps> = ({
	ariaLabel,
	className,
	linkClassName,
	links,
	listItemClassName,
	menuAccordion,
	privacySettings: PrivacySettings,
}) => {
	if (!links || links.length === 0) {
		return null;
	}

	const menu = links;
	const menuSlug = slugify(ariaLabel);

	// Recursive function to render links and their children
	const renderMenuItems = (
		items: MenuItemEntity[],
		isMenuAccordion: boolean,
		menuId?: string,
	) => {
		return (
			<ul
				className={className}
				id={menuId}
				style={
					{
						'--menu-columns': items.length < 6 ? items.length : 6,
					} as CSSProperties
				}
			>
				{items.map((link) => {
					const menuItemId = `${menuSlug}-${link.ID}`;
					const hasChildren = link.children && link.children.length > 0;
					const hasAccordion = isMenuAccordion && hasChildren;
					const linkClsx = clsx(linkClassName, {
						'accordion-hidden': hasAccordion,
					});
					const buttonClsx = clsx(linkClassName, {
						'accordion-button accordion-visible': isMenuAccordion,
					});

					return (
						<li className={listItemClassName} key={link.ID}>
							<MenuLink className={linkClsx} link={link} />
							{hasAccordion && (
								<Accordion
									buttonClassName={buttonClsx}
									buttonLabel={link.title}
								>
									{renderMenuItems(link.children!, false, menuItemId)}
								</Accordion>
							)}

							{hasChildren && (
								<>{renderMenuItems(link.children!, false, menuItemId)}</>
							)}
						</li>
					);
				})}

				{PrivacySettings && (
					<PrivacySettings
						buttonClassName={linkClassName}
						listItemClassName={listItemClassName}
					/>
				)}
			</ul>
		);
	};

	return (
		<nav aria-label={ariaLabel}>
			{renderMenuItems(menu, menuAccordion || false)}
		</nav>
	);
};
