'use client';

import { clsx } from 'clsx';
import Link from 'next/link';
import { type FC, useMemo } from 'react';
import { isSvg } from '@/utils/image-utils';
import { FocusTrap } from 'focus-trap-react';
import { isClient } from '@/utils/is-client';
import { usePathname } from 'next/navigation';
import { useKey } from '@/utils/hooks/use-key';
import { Image } from '@/components/raven/Image';
import Close from '@themaven-net/raven-assets/Close';
import { useSiteContext } from '@/context/SiteContext';
import Hamburger from '@themaven-net/raven-assets/Hamburger';
import Newsletter from '@themaven-net/raven-assets/Newsletter';
import { selectSubNavigation } from '@/utils/generate-submenu';
import { Navigation } from '@/components/raven/global/Navigation';
import type { AppSettings } from '@/utils/data/fetch-app-settings';
import { useNavigationContext } from '@/context/NavigationContext';
import { SubNavigation } from '@/components/raven/global/SubNavigation';
import { NewsletterDropdown } from '@/components/raven/global/NewsletterDropdown';
import { SearchWidgetTrigger } from '@/components/raven/global/SearchWidgetTrigger';

import './styles.css';
import styles from './styles.module.css';

export interface HeaderProps {
	menus: AppSettings['menus'];
	settings: AppSettings['settings'];
}

export const Header: FC<HeaderProps> = ({ menus, settings }) => {
	const {
		handleSetActiveMenuItem,
		handleSetIsMenuOpen,
		handleSetIsNewsletterOpen,
		isMenuOpen,
		isNewsletterOpen,
		isSubNavigationOpen,
		lastVisitedPath,
		subNavigation,
	} = useNavigationContext();
	const { config } = useSiteContext();
	const pathname = usePathname();
	const logo = settings?.header_logo;
	const isSubNavEnabled = !isNewsletterOpen && settings?.subnav_enabled;
	const hasSubNav = subNavigation && subNavigation.length > 0;
	const canRenderSubNav = isSubNavEnabled && hasSubNav;

	const selectedSubMenu = useMemo(() => {
		return selectSubNavigation(subNavigation, pathname, lastVisitedPath);

		// Don't include the lastVisitedPath in the deps array as it will cause the animation of the
		// subnav to not work correctly
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname, subNavigation]);

	const bodyRef = {
		current: isClient ? document.body : null,
	};

	useKey(
		'Escape',
		() => {
			handleSetIsMenuOpen(false);
			handleSetIsNewsletterOpen(false);
		},
		bodyRef,
	);

	const handleNewsletterOpen = () => {
		handleSetActiveMenuItem(-1);
		handleSetIsMenuOpen(false);
		handleSetIsNewsletterOpen(true);
	};

	return (
		<FocusTrap active={isNewsletterOpen}>
			<header
				className={clsx(
					'site-header',
					styles.siteHeader,
					isMenuOpen || isNewsletterOpen ? styles.siteHeaderOverlay : '',
				)}
			>
				<div className={styles.siteHeaderContainer}>
					{!isNewsletterOpen && (
						<button
							aria-label="Get Our Newsletter"
							className={styles.siteHeaderNewsletterButton}
							onClick={handleNewsletterOpen}
							type="button"
						>
							<span className={styles.siteHeaderNewsletterButtonText}>
								Get Our Newsletter
							</span>
							<Newsletter height={18} width={18} />
						</button>
					)}
					{logo && (
						<Link
							aria-label="Go to Homepage"
							className={styles.siteHeaderLink}
							href="/"
							tabIndex={isNewsletterOpen ? -1 : 0}
						>
							<Image
								alt={logo.alt_text}
								height={logo.media_details.height}
								priority
								sizes="200px"
								src={logo.source_url}
								unoptimized={isSvg(logo.source_url)}
								width={logo.media_details.width}
							/>
						</Link>
					)}
					{isNewsletterOpen ? (
						<button
							aria-label="Close Newsletter"
							className={styles.siteHeaderNewsletterButtonClose}
							onClick={() => handleSetIsNewsletterOpen(false)}
							type="button"
						>
							<Close />
						</button>
					) : (
						<button
							aria-controls="header"
							aria-expanded={isMenuOpen}
							aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
							className={styles.siteHeaderMenuButton}
							onClick={() => handleSetIsMenuOpen(true)}
							type="button"
						>
							<Hamburger height={20} width={20} />
						</button>
					)}
					{/* if googleSearchSiteConfigId exists and not an empty string, render the search widget */}
					{config?.googleSearchSiteConfigId && (
						<div className={styles.siteHeaderSearchTrigger}>
							<SearchWidgetTrigger />
						</div>
					)}
				</div>

				{!isNewsletterOpen && <Navigation logo={logo} menus={menus} />}
				<NewsletterDropdown settings={settings} />
				{canRenderSubNav && (
					<SubNavigation
						isSubNavigationOpen={isSubNavigationOpen}
						menu={selectedSubMenu}
					/>
				)}
			</header>
		</FocusTrap>
	);
};
