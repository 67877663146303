import clsx from 'clsx';
import type { FC } from 'react';
import { decodeEntities } from '@/utils/post-helpers';
import { InternalLink } from '@/components/InternalLink';

import styles from './styles.module.css';

interface ArticleCategoryProps {
	category: string;
	className?: string;
	link?: string;
}

export const ArticleCategory: FC<ArticleCategoryProps> = ({
	category,
	className,
	link,
}) => {
	const cx = clsx(styles.articleCategory, className);
	const decodedCategory = decodeEntities(category);

	return link ? (
		<InternalLink
			aria-label={`More article from ${decodedCategory}`}
			className={cx}
			href={link}
		>
			{decodedCategory}
		</InternalLink>
	) : (
		<div className={cx}>{decodedCategory}</div>
	);
};
