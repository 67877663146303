import clsx from 'clsx';
import type { MenuItemEntity } from '@/types/entities';
import { useNavigationContext } from '@/context/NavigationContext';
import { MegaMenuItem } from '@/components/raven/MegaMenu/MegaMenuItem';
import { useMemo, type FC, useState, useEffect, useCallback } from 'react';

import styles from './styles.module.css';

interface MenuProps {
	ariaLabel: string;
	links?: MenuItemEntity[];
}

const getMenuItemWrapper = (index: number) => {
	const menuItem = document.querySelector(`#menu-item-${index} .menu-wrapper`);

	if (!menuItem) {
		return null;
	}

	return menuItem as HTMLElement;
};

export const MegaMenu: FC<MenuProps> = ({ ariaLabel, links }) => {
	const {
		activeMenuItem,
		handleSetActiveMenuItem,
		handleSetIsMenuOpen,
		isMenuOpen,
		isSmallMenu,
	} = useNavigationContext();
	const [showBackground, setShowBackground] = useState(false);

	const currentBackgroundHeight = useMemo(() => {
		if (activeMenuItem === -1) {
			return 0;
		}

		const activeMenuItemElement = document.querySelector(
			`#menu-item-${activeMenuItem} > div:nth-child(2)`,
		) as HTMLElement | null;

		if (!activeMenuItemElement) {
			return 0;
		}

		return activeMenuItemElement.offsetHeight;
	}, [activeMenuItem]);

	const toggleActiveMenu = useCallback(
		(index: number, isSubNav: boolean) => {
			if (activeMenuItem === index) {
				if (!isSubNav) {
					handleSetIsMenuOpen(false);
				}

				handleSetActiveMenuItem(-1);
				return;
			}

			if (index > 0) {
				handleSetIsMenuOpen(true);
			} else {
				handleSetIsMenuOpen(false);
			}

			handleSetActiveMenuItem(index);

			if (!isMenuOpen) {
				setShowBackground(false);
			}
		},
		[activeMenuItem, handleSetActiveMenuItem, handleSetIsMenuOpen, isMenuOpen],
	);

	useEffect(() => {
		const activeMenuItemWrapper = getMenuItemWrapper(activeMenuItem);

		if (!activeMenuItemWrapper) {
			setShowBackground(false);
			return;
		}

		requestAnimationFrame(() => {
			const onAnimationEnd = (event: AnimationEvent) => {
				if (event.animationName === `${styles.slideDownMenu}`) {
					setShowBackground(true);
				} else {
					setShowBackground(false);
				}
				activeMenuItemWrapper.removeEventListener(
					'animationend',
					onAnimationEnd,
				);
			};

			activeMenuItemWrapper.addEventListener('animationend', onAnimationEnd);
		});
	}, [activeMenuItem]);

	if (!links || !Array.isArray(links) || links.length === 0) {
		return null;
	}

	return (
		<nav
			aria-label={ariaLabel}
			className={clsx(
				styles.menuWrapper,
				showBackground ? styles.menuWrapperSubmenuActive : '',
			)}
		>
			<ul className={styles.menuSectionsList}>
				{links.map((link) => (
					<MegaMenuItem
						activeMenuItem={activeMenuItem}
						isSmallMenu={isSmallMenu}
						key={link.ID}
						link={link}
						toggleActiveMenu={toggleActiveMenu}
					/>
				))}
			</ul>

			<span
				aria-hidden="true"
				className={clsx(styles.menuWrapperBackground, {
					[styles.menuWrapperBackgroundOpen]: showBackground,
				})}
				style={{ height: currentBackgroundHeight }}
			/>
		</nav>
	);
};
