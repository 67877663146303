'use client';

import clsx from 'clsx';
import { type FC, useCallback } from 'react';
import { ComplianceType } from '@/utils/consent/constants';
import { useConsentContext } from '@/context/ConsentContext';
import { PrivacySettingsIcon } from '@/assets/surfer/icons/PrivacySettingsIcon';

import styles from './styles.module.css';

export interface PrivacySettingsProps {
	buttonClassName?: string;
	listItemClassName?: string;
}

export const PrivacySettings: FC<PrivacySettingsProps> = ({
	buttonClassName,
	listItemClassName,
}) => {
	const { consentConfig, sourcepointContext } = useConsentContext();

	const showPrivacySettings = useCallback(() => {
		if (
			sourcepointContext &&
			sourcepointContext.consentType === ComplianceType.GPP &&
			window._sp_.usnat
		) {
			// TODO:  Add 'subsequent_display' Google Analytics event
			window._sp_.usnat.loadPrivacyManagerModal(
				Number(consentConfig?.gppPrivacyManagerId),
			);
		}

		if (
			sourcepointContext &&
			sourcepointContext.consentType === ComplianceType.GDPR &&
			window._sp_.gdpr
		) {
			window._sp_.gdpr.loadPrivacyManagerModal(
				Number(consentConfig?.gdprPrivacyManagerId),
			);
		}
	}, [sourcepointContext, consentConfig]);

	if (!sourcepointContext) {
		return null;
	}

	return (
		<li className={listItemClassName}>
			<button
				className={clsx(styles.privacySettingsButton, buttonClassName)}
				onClick={() => showPrivacySettings()}
				type="button"
			>
				Privacy Settings
				<PrivacySettingsIcon />
			</button>
		</li>
	);
};
